import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../services/AuthService";
import { toast } from "react-toastify";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const HeaderTop = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AppContext);

  const handleLogout = async () => {
    await logout()
      .then((res) => {
        toast(res?.data?.message, { type: "success" });

        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        setIsAuthenticated(0);

        navigate("/");
      })
      .catch((error) => {
        if (error.response.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error.response.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="header-top header-wrapper hide-mobile">
      <div className="container">
        <div className="column column-left">
          <nav className="site-menu horizontal">
            <ul id="menu-top-left" className="menu">
              <li
                id="menu-item-1893"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1893"
              >
                <Link to="/about-us">About Us</Link>
              </li>
              {localStorage.getItem("is_authenticated") ? (
                <li
                  id="menu-item-1892"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1892"
                >
                  <Link to="/my-account">My account</Link>
                </li>
              ) : null}

              {localStorage.getItem("is_authenticated") && (
                <li
                  id="menu-item-1891"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1891"
                >
                  <Link to="/wishlist">Wishlist</Link>
                </li>
              )}
              {/* <li
                id="menu-item-1890"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1890"
              >
                <a href="#/order-tracking/">Order Tracking</a>
              </li> */}
              {localStorage.getItem("is_authenticated") ? (
                <li
                  id="menu-item-1890"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1890"
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLogout()}
                  >
                    {" "}
                    Logout{" "}
                  </a>
                </li>
              ) : null}
            </ul>
          </nav>
        </div>

        <div className="column column-right">
          <div className="topbar-notice">
            <i className="klbth-icon-secure"></i>
            <span>100% Secure delivery without contacting the courier</span>
          </div>

          <div className="text-content">
            Need help? Call Us:{" "}
            <a href="tel:0020500">
              <strong style={{ color: "#2bbef9" }}>
                {" "}
                <a href="tel:+19082515039">+1 908 251 5039</a>
              </strong>
            </a>
          </div>

          <div className="header-switchers">
            <nav className="store-language site-menu horizontal">
              <ul id="menu-top-right-1" className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1832">
                  <Link href="">English</Link>
                  <ul className="sub-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1838">
                      <Link href="">English</Link>
                    </li>
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1833">
                      <Link href="">Spanish</Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1834">
                      <Link href="">German</Link>
                    </li> */}
                  </ul>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1835">
                  <Link href="">USD</Link>
                  <ul className="sub-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1839">
                      <Link href="">USD</Link>
                    </li>
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1836">
                      <Link href="">INR</Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1837">
                      <Link href="">GBP</Link>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderTop;
