import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getLogos } from "../../services/CartService";

export default function LogoScroll() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  var settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: data?.length > 7 ? 8 : data?.length,
    slidesToScroll: data?.length > 7 ? 2 : 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };


     const getData = () => {
       getLogos()
         .then((response) => {
          setdata(response?.data?.data);
         })
         .catch((error) => {
           console.log("Error", error);
         });
     };
  return (
    <div
    // className="p-5"
    >
      <Slider {...settings}>
        {data?.map((x) => (
          <div>
            <a href={x?.url} target="_blank">
              <img
                className="p-2 bg-black"
                src={x?.image}
                alt="Logo"
                style={{ width: "130px", height: "75px", margin: "10px" }}
              />
            </a>
          </div>
        ))}
        {/* <div>
          <img
            className="p-2 bg-black"
            src="/images/2.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/3.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/4.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/5.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/6.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/7.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/8.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/9.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/10.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/11.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/12.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/13.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/14.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/15.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/16.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/17.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/18.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/19.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div> */}
      </Slider>
    </div>
  );
}
